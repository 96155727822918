.app-container {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;

  display: flex;
  /* Usar flexbox para alinear el contenido */
  flex-direction: column;
  /* Alinear los hijos verticalmente */
  justify-content: center;
  /* Centrar el contenido a lo largo del eje principal */
  align-items: center;
  /* Centrar el contenido a lo largo del eje transversal */
  text-align: center;
  
  /* Ajustar a la altura de la ventana */
  margin-top: 0;
  /* Remover el margen superior */
}



h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal
}
.contenido-principal {
  margin-top: 50px;
  margin-bottom: 50px;
}

.contenido-principal h1 {
  color: #333;
  font-size: 5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
  margin-bottom: 0;
}
.primary{
  background-color: #ff7f2f;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  font-size: 2rem;
}
button.primary:hover {
  background-color: #ff6200;
}
.contenido-secundario form {
  margin: 20px 0;
  margin-bottom: 30px;
}

input[type="text"] {
  padding: 8px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #5cb85c;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #449d44;
}

.nombres-agregados {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 70%;
  margin: 30px auto;
  border: 1px dashed rgb(211, 211, 211);
  padding: 20px;
}

.nombre-etiqueta {
  background-color: #f3f3f3;
  border-radius: 4px;
  /* Bordes redondeados similares al botón */
  padding: 8px 15px;
  /* Aumentar el padding para más espacio dentro de las tags */
  margin: 10px;
  /* Aumentar el margen para más espacio entre las tags */
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  position: relative;
  /* Para posicionar la 'X' en la esquina */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.seleccionado{
    background-color: #ecfdff;
}
.nombre-etiqueta button {
  position: absolute;
  top: -5px;
  /* Ajusta estos valores para alinear la 'X' como desees */
  right: -5px;
  background-color: #d9534f;
  border-radius: 50%;
  /* Botón redondo para la 'X' */
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  /* Tamaño más pequeño para la 'X' */
  height: 16px;
  text-align: center;
  border: none;
  color: white;
  font-size: 12px;
  cursor: pointer;
}

.nombre-etiqueta button:hover {
  background-color: #c9302c;
}